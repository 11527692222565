import MuiTable from "./MuiTable";
import MuiButton from "./MuiButton";
import MuiDialog from "./MuiDialog";
import MuiBackdrop from "./MuiBackdrop";
import MuiTypography from "./MuiTypography";
import MuiFormHelperText from "./MuiFormHelperText";

export default {
	MuiTable,
	MuiButton,
	MuiDialog,
	MuiBackdrop,
	MuiTypography,
	MuiFormHelperText
}