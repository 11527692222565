export default {
	DRAFT: {
		color: "#8E8E8E",
		label: "Черновик",
	},
	NEW: {
		color: "#10B879",
		label: "Новый",
	},
	CANCELED: {
		color: "#F5386A",
		label: "Отменен",
	},
	CONFIRMED: {
		color: "#2D7AC2",
		label: "Подтвержден",
	},
	PROCESS: {
		color: "#2D4EC2",
		label: "В процессе",
	},
	COMPLETED: {
		color: "#8E8E8E",
		label: "Выполнен",
	},
}