export default {
	styleOverrides: {
		root: {
			"@media(max-width: 1199px)": {
				"& .MuiDialogTitle-root": {
					padding: 8
				},
				"& .MuiDialogContent-root": {
					padding: 8
				},
			}
		},
		paper: {
			"@media(max-width: 1199px)": {
				width: "calc(100% - 12px)",
				maxWidth: "100%",
				margin: 6,
				verticalAlign: "top",

				"&.MuiDialog-paperScrollBody": {
					maxWidth: "calc(100% - 12px)",
				}
			}
		}
	}
}
