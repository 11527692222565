import React from "react";
import { Box, Button, Grid, IconButton, ListItemIcon, ListItemText, MenuItem, useMediaQuery } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
    AssignmentIndRounded as AssignmentIndRoundedIcon,
    FormatListBulletedRounded as FormatListBulletedRoundedIcon,
    MenuRounded as MenuRoundedIcon
} from "@mui/icons-material";
import { Link, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../../states/global";
import roles from "../../../../constants/roles";
import { MobileSidebar } from "../index";
import {useLocation, useHistory} from 'react-router-dom';
import arrowLeft from '../../../../assets/svg/header/arrow-left.svg';

const Header = ({ isAuth }) => {
    const classes = useStyles();
    const routeMatch = useRouteMatch();
	const { user } = useSelector(state => state.global);
	const dispatch = useDispatch()
    const isDesktop = useMediaQuery('(min-width:1200px)');
    const [ isOpenDrawer, setIsOpenDrawer ] = React.useState(false);
    const {pathname} = useLocation();
    const history = useHistory();

    const _menu = () => {
        return [
            {
                label: "Заявки",
                path: "/applications",
                paths: [ "/applications", "/applications/:id" ],
                Icon: FormatListBulletedRoundedIcon
            },
            {
                label: "Механики",
                path: "/mechanics",
                Icon: AssignmentIndRoundedIcon
            }
        ]
    }
    const _isSelected = (item) => {
        return Boolean(
            routeMatch?.path === item.path || ( item.paths || [] ).includes(routeMatch?.path)
        )
    }

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && ( event.key === 'Tab' || event.key === 'Shift' )) {
            return;
        }

        setIsOpenDrawer(prevState => !prevState);
    };

	const handleLogout = () => {
		dispatch(logoutUser());
	};


    return (
        <Box className={ classes.root }>
            <div className={classes.logoBlock}>
                <img onClick={() => history.goBack()} className={`${classes.backLogo} ${pathname === '/applications' || pathname === '/authorization' ? classes.hidden : ''}`} src={arrowLeft} alt=""/>
                <a href="/" className={ classes.logo }>
                    <img src={ require("../../../../assets/logos/logo-full.png").default }/>
                </a>
            </div>

            { isDesktop
                ? <>
                    { Boolean(isAuth) && (
                        <>
                            <Grid container spacing={ 1 } ml={ 3 }>
                                { _menu().map((item, index) => (
                                    <Grid item key={ `menu-item-${ index }` }>
                                        <MenuItem
                                            selected={ _isSelected(item) }
                                            component={ Link }
                                            to={ item.path }
                                        >
                                            <ListItemIcon>
                                                <item.Icon sx={ { fontSize: 24 } }/>
                                            </ListItemIcon>
                                            <ListItemText>
                                                { item.label }
                                            </ListItemText>
                                        </MenuItem>
                                    </Grid>
                                )) }
                            </Grid>
                            <Grid container justifyContent='flex-end'>
                                <Box className={ classes.rowContent }>
                                    <Box className={ classes.columnContent }>
                                        <p>ФИО: { user?.firstName || '' } { user?.lastName || '' }</p>
                                        <p>Роль: { roles[user?.role || ''] }</p>
                                    </Box>
                                    <Button
                                        color='primary'
                                        variant='contained'
                                        onClick={ handleLogout }
                                    >
                                        Выйти
                                    </Button>
                                </Box>
                            </Grid>
                        </>
                    ) }
                </>
                : <>
                    { Boolean(isAuth) && (
                        <>
                            <MobileSidebar
                                _menu={ _menu }
                                _isSelected={ _isSelected }
                                isOpen={ isOpenDrawer }
                                position='right'
                                onClose={ toggleDrawer }
                                onLogout={ handleLogout }
                            />

                            <IconButton
                                color="primary"
                                onClick={ toggleDrawer }
                            >
                                <MenuRoundedIcon color='primary'/>
                            </IconButton>
                        </>
                    ) }
                </>
            }
        </Box>
    );
}

const useStyles = makeStyles(() => ( {
    root: {
        display: "flex",
        alignItems: "center",

        height: 60,
        backgroundColor: "white",

        padding: "12px 24px",
        boxSizing: "border-box",
        justifyContent: 'space-between'
    },

    logo: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "center",
        height: "100%",

        "& img": {
            height: 26
        },

        "@media(max-width: 1199px)": {
            marginRight: "auto"
        }
    },

    rowContent: {
        display: 'flex',
        alignItems: 'center',
        gap: 32,
    },

    columnContent: {
        display: 'flex',
        flexDirection: "column",
        alignItems: 'flex-start',
        gap: 5,
    },
    logoBlock: {
        display: 'flex',
        alignItems: 'center',
        gap: 10
    },
    backLogo: {
        width: '40px',
        height: '40px',
        cursor: 'pointer'
    },
    hidden: {
        visibility: 'hidden'
    }
} ))

export default React.memo(Header)
