import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";

const ApplicationInfo = (props) => {
    const {
        application
    } = props;
    const classes = useStyles();

    return (
        <>
            <Box mb={ 1 }>
                <Typography variant="h3" textAlign="left">Информация о ЗН</Typography>
            </Box>
            <Grid className={ classes.root } container spacing={ 1 }>
                <Grid item>
                    ID - { application.id || 'ID не найден' };
                </Grid>
                <Grid item>
                    Трицепс ID - { application.tricepsOrderId || "Трицепс ID не найден" };
                </Grid>
            </Grid>
        </>
    );
};

const useStyles = makeStyles(() => ({
    root: {
        "& b": {
            fontWeight: "500",
            textDecoration: "underline"
        }
    }
}));

export default React.memo(ApplicationInfo)
