import Compressor from "compressorjs";

const optimizationImageVideo = async (files = []) => {
	let filesOptimization = [];

	await Promise.all(files.map(async (file) => {
		if ((file.file.type || "").includes("video")) {
			filesOptimization.push(file);
		}
		if ((file.file.type || "").includes("image")) {
			const newFile = await compress(file.file);
			filesOptimization.push({
				...file,
				file: newFile
			});
		}
	}));

	return filesOptimization
}
async function compress(file) {
	return await new Promise((resolve, reject) => {
		new Compressor(file, {
			quality: 0.6,
			success: resolve,
			error: reject,
		});
	});
}

export default optimizationImageVideo