import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            height: 32,
            padding: "8px 12px",
            borderRadius: 4,
            boxShadow: "none",
            textTransform: "initial"
        },
        contained: {

        },
        outlined: {
            borderColor: palette.primary.main,
            color: palette.primary.main,
            backgroundColor: "white"
        }
    }
}
