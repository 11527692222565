import React from "react";
import { Store } from "react-notifications-component";

const Notification = (
  {
      title = 'Системное уведомление',
      message = '',
      type = 'info'
  }
) => {
    Store.addNotification({
        title: title,
        message: (<React.Fragment>
            <div dangerouslySetInnerHTML={{
                __html: message
            }}/>
        </React.Fragment>),
        type: type,
        insert: 'bottom',
        container: 'bottom-right',
        dismiss: {
            duration: 3000,
            onScreen: false,
            pauseOnHover: true,
            delay: 0
        }
    })
}

const notificationTypes = {
    info: "info",
    success: "success",
    warning: "warning",
    error: "danger",
};

export {
    Notification,
    notificationTypes
}
