import {palette} from "../common";

export default {
    styleOverrides: {
        root: {
            backgroundColor: 'transparent',
            borderCollapse: 'separate',
            borderSpacing: 0,
            '& .MuiTableHead-root': {
                backgroundColor: "white",
                '& .MuiTableCell-head': {
                    fontSize: 14,
                    lineHeight: "16px",
                    color: "#282828",
                    borderRight: '1px solid #F0F0F0',
                    borderLeft: '1px solid #F0F0F0',
                    padding: "10px 12px",
                    fontWeight: "700",
                    "&:first-child": {
                        borderLeft: 0,
                        borderRadius: '6px 0 0 0',
                    },
                    "&:last-child": {
                        borderRight: 0,
                        borderRadius: '0 6px 0 0',
                    }
                }
            },
            '& .MuiTableBody-root': {
                '& .MuiTableRow-root': {
                    height: "100%",
                    position: "relative",
                    cursor: "pointer",

                    "& > .MuiButtonBase-root": {
                        width: 52,
                        height: 52,
                        position: 'sticky',
                        right: 0,
                        borderRadius: 5,
                        background: "linear-gradient(90deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,1) 100%)",
                    }
                },
                '& .MuiTableCell-body': {
                    height: "100%",
                    borderRight: '1px solid #F0F0F0',
                    borderLeft: '1px solid #F0F0F0',
                    fontWeight: 400,
                    fontSize: 14,
                    color: '#434343',
                    borderBottom: 0,
                    padding: "5px 12px",
                    "&:first-child": {
                        borderLeft: 0,
                    },
                    "&:last-child": {
                        borderRight: 0,
                    },
                    "& .MuiCheckbox-root": {
                        color: "#C8C8C8",
                    },
                    "& .Mui-checked": {
                        color: palette.primary.main,
                    },
                }
            }
        }
    }
}