import React from 'react';
import { Box, Button, Drawer, ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import roles from "../../../../constants/roles";
import { Link } from "react-router-dom";

const MobileSidebar = (props) => {
    const {
        _menu,
        _isSelected,
        isOpen,
        position,
        onClose,
        onLogout,
    } = props;

    const classes = useStyles();
    const {
        user
    } = useSelector(state => state.global);
    return (
        <Box>
            <Drawer
                anchor={ position }
                open={ isOpen }
                onClose={ onClose }
            >
                <Box mb={ 2 } className={ classes.root }>
                    <Box className={ classes.columnContent }>
                        <p>ФИО: { user?.firstName || '' } { user?.lastName || '' }</p>
                        <p>Роль: { roles[user?.role || ''] }</p>
                    </Box>
                    <Button
                        fullWidth
                        color='primary'
                        variant='contained'
                        onClick={ onLogout }
                    >
                        Выйти
                    </Button>
                </Box>
                { _menu().map((item, index) => (
                    <Box key={ `menu-item-${ index }` }>
                        <MenuItem
                            selected={ _isSelected(item) }
                            component={ Link }
                            to={ item.path }
                        >
                            <ListItemIcon>
                                <item.Icon sx={ { fontSize: 24 } }/>
                            </ListItemIcon>
                            <ListItemText>
                                { item.label }
                            </ListItemText>
                        </MenuItem>
                    </Box>
                )) }
            </Drawer>
        </Box>
    );
};

const useStyles = makeStyles({
    root: {
        display: "flex",
        flexDirection: 'column',
        gap: 16,
        padding: '10px 15px',
    },

    columnContent: {
        display: "flex",
        flexDirection: 'column',
        gap: 5,
    },
});

export default React.memo(MobileSidebar);
