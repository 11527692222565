import React from "react";
import {
    Box,
    Grid,
    Button,
    TextField,

    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    makeStyles
} from "@mui/styles";
import {
    DateRangePicker
} from "@mui/lab";
import orderStatus from "../../../../../constants/orderStatus";
import filter from "../../../../mechanics/Mechanics/components/Filter";

const Filter = (props) => {
    const {
        initFilter,
        onChange,
        onReset
    } = props;
    const [filter, setFilter] = React.useState({...initFilter});
    const classes = useStyles();

    React.useEffect(() => {
        setFilter({...initFilter});
    }, [initFilter]);


    const handleChangeFilter = ({target}) => {
        const {name, value} = target;

        let newFilter = {...filter};
        newFilter[name] = value;
        setFilter(newFilter);
    }
    const handleChangeFilterDateRange = (value) => {
        let newFilter = {...filter}
        newFilter.dateFrom = value[0] || null;
        newFilter.dateTo = value[1] || null;
        setFilter(newFilter);
    }

    const handleEnterPress = (event) => {
        if (event.code === "Enter" || event.code === "NumpadEnter") {
            event.preventDefault();
            onChange(filter);
        }
    };


    return (
        <Box className={classes.root}>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <TextField
                        value={filter.userName}
                        fullWidth
                        name="userName"
                        label="ФИО клиента"
                        size="small"
                        placeholder="Введите"
                        onChange={handleChangeFilter}
                        onKeyDown={handleEnterPress}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <TextField
                        value={filter.userPhone}
                        fullWidth
                        name="userPhone"
                        label="Телефон клиента"
                        size="small"
                        placeholder="79000000000"
                        onChange={handleChangeFilter}
                        onKeyDown={handleEnterPress}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <FormControl fullWidth size="small">
                        <InputLabel>Статус заявки</InputLabel>
                        <Select
                            value={filter.status}
                            label="Статус заявки"
                            size="small"
                            name="status"
                            onChange={handleChangeFilter}
                            onKeyDown={handleEnterPress}
                        >
                            {Object.keys(orderStatus).map((key) => {
                                const item = orderStatus[key];
                                return (
                                    <MenuItem key={`orderStatus-${key}`} value={key}>
                                        <Grid container spacing="8px" wrap="nowrap" alignItems="center">
                                            <Grid item>
                                                <Box width="8px" height="8px" borderRadius="100%" bgcolor={item.color}/>
                                            </Grid>
                                            <Grid item sx={{color: item.color}}>
                                                {item.label}
                                            </Grid>
                                        </Grid>
                                    </MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DateRangePicker
                        startText="Дата начала"
                        endText="Дата окончания"
                        value={[filter.dateFrom || null, filter.dateTo || null]}
                        onChange={handleChangeFilterDateRange}
                        onKeyDown={handleEnterPress}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <Grid container spacing={1} wrap="nowrap" alignItems="center">
                                    <Grid item sx={{flex: 1}}>
                                        <TextField {...startProps} size="small" fullWidth/>
                                    </Grid>
                                    <Grid item>
                                        -
                                    </Grid>
                                    <Grid item sx={{flex: 1}}>
                                        <TextField {...endProps} size="small" fullWidth/>
                                    </Grid>
                                </Grid>
                            </React.Fragment>
                        )}
                    />
                </Grid>

                <Grid item xs={12} lg="auto" marginLeft="auto">
                    <Grid container spacing={2}>
                        <Grid item xs={6} lg="auto">
                            <Button
                                variant="outlined"
                                size="small"
                                fullWidth
                                sx={{height: 43}}
                                onClick={onReset}
                            >Сбросить</Button>
                        </Grid>
                        <Grid item xs={6} lg="auto">
                            <Button
                                variant="contained"
                                size="small"
                                fullWidth
                                sx={{height: 43}}
                                onClick={onChange.bind(this, filter)}
                            >Применить</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles(() => ({
    root: {
        padding: 20,
        boxSizing: "border-box",
        backgroundColor: "white",
        borderRadius: 14,
        border: "1px solid #F0F0F0",
        marginBottom: 16,

        "@media(max-width: 1199px)": {
            padding: 12,
        }
    }
}));

export default Filter