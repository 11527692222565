import React from "react";
import {
	Box,
	Grid,
	Button,
	Tooltip,
	Checkbox,
	IconButton,
	Typography,
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {
	Edit as EditIcon
} from "@mui/icons-material";
import {convertorNumber} from "../../../../../helper/convertor";
import BigNumber from "bignumber.js";
import {isIos} from "../../../../../common/Platform";
import VideoPreview from "../../../../../components/VideoPreview";

const WorkList = (props) => {
	const {
		data,
		disabledEdit,

		onOpenDialogImagesVideos
	} = props;
	const classes = useStyles();
	const [totalPrices, setTotalPrices] = React.useState({
		costSpareParts: "",
		costWork: "",
		costTotal: "",
	});
	React.useEffect(() => {
		calcTotalPrices();
	}, [data])

	const calcTotalPrices = () => {
		const list = [...data].filter((t) => t.enable);

		let costSpareParts = 0;
		let costWork = 0;

		list.map((work) => {
			costWork = new BigNumber(costWork).plus(work.cost).toNumber();

			(work.estimate || []).map((t) => {
				costSpareParts = new BigNumber(costSpareParts).plus(t.partsCost).toNumber();
			})
		})

		setTotalPrices({
			costSpareParts: costSpareParts,
			costWork: costWork,
			costTotal: new BigNumber(costSpareParts).plus(costWork).toNumber(),
		})
	}

	return (
		<Grid className={classes.root} container spacing={2}>
			<Grid item xs={12}>
				<Typography variant="h3" textAlign="left">Перечень работ</Typography>
			</Grid>

			{data.map((order) => (
				<Grid key={`order-${order.id}`} item xs={12} lg={6}>
					<OrderCard
						order={order}
						disabledEdit={disabledEdit}

						onOpenDialogImagesVideos={onOpenDialogImagesVideos}
					/>
				</Grid>
			))}

			<Grid className={classes.prices} item xs={12}>
				<Grid container alignItems="flex-end" spacing="8px" flexDirection="column">
					<Grid item>
						<Typography variant="h5">
							Стоимость запчастей: <span/> {convertorNumber(totalPrices.costSpareParts, 2, '.')}₽
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h5">
							Стоимость работ: <span/> {convertorNumber(totalPrices.costWork, 2, '.')}₽
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="h4">
							Итоговая стоимость: <span/> {convertorNumber(totalPrices.costTotal, 2, '.')}₽
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}
const OrderCard = React.memo((props) => {
	const {
		order,
		disabledEdit,

		onOpenDialogImagesVideos
	} = props;
	const classes = useStyles();
	const [totalPrices, setTotalPrices] = React.useState({
		costSpareParts: "",
		costWork: "",
		costTotal: "",
	});
	React.useEffect(() => {
		calcTotalPrices();
	}, [order])

	const calcTotalPrices = () => {
		let costSpareParts = 0;
		let costWork = order.cost;

		(order.estimate || []).map((t) => {
			costSpareParts = new BigNumber(costSpareParts).plus(t.partsCost).toNumber();
		})

		setTotalPrices({
			costSpareParts: costSpareParts,
			costWork: costWork,
			costTotal: new BigNumber(costSpareParts).plus(costWork).toNumber(),
		})
	}

	return (
		<Box className={classes.orderCard}>
			<Box className={classes.orderCardHead}>
				<div className={classes.orderCardName}>
					{order.description}
				</div>
			</Box>
			<Box className={classes.orderCardBody}>
				<div className={classes.orderCardEstimateTitle}>Перечень запчастей</div>
				<ol className={classes.orderCardEstimate}>
					{(order?.estimate || []).map((sparePart, index) => (
						<li>
							{sparePart.description} ({convertorNumber(sparePart.partsCost)}₽)
						</li>
					))}
				</ol>

				{Boolean((order.pictures || []).length > 0) && (
					<>
						<div className={classes.filesTitle}>Фото / видео</div>
						<Grid className={classes.files} container spacing={1}>
							{(order.pictures || []).map((file, index) => {
								const isVideo = Boolean((file?.file?.type || "").includes("video"));
								if (!!isVideo) {
									return (
										<Grid item>
											<VideoPreview
												file={file}
												onClick={onOpenDialogImagesVideos.bind(this, order.pictures || [], index)}
											/>
										</Grid>
									)
								}
								return (
									<Grid item>
										<img
											src={URL.createObjectURL(file.file)}
											onClick={onOpenDialogImagesVideos.bind(this, order.pictures || [], index)}
										/>
									</Grid>
								)
							})}
						</Grid>
					</>
				)
				}
			</Box>
			<Box className={classes.orderCardFooter}>
				<Box className={classes.orderPriceSmall}>
					Запчасти: <span>{convertorNumber(totalPrices.costSpareParts)}₽</span>
				</Box>
				<Box className={classes.orderPriceSmall}>
					Работа: <span>{convertorNumber(totalPrices.costWork)}₽</span>
				</Box>
				<Box className={classes.orderPriceBig}>
					Всего: <span>{convertorNumber(totalPrices.costTotal)}₽</span>
				</Box>
			</Box>
		</Box>
	)
})

const useStyles = makeStyles(() => ({
	root: {
		"@media(max-width: 1199px)": {
			paddingBottom: 100
		}
	},
	prices: {

		"@media(max-width: 1199px)": {
			position: "fixed",
			bottom: 0,
			left: 0,
			right: 0,
			backgroundColor: "white",
			borderRadius: "8px 8px 0 0",
			padding: 12,
			boxShadow: "0px 2px 8px rgb(142 150 168 / 24%)",

			"& .MuiTypography-root": {
				display: "flex",
				flexDirection: "row",
				flewWrap: "nowrap",

				fontSize: 14,
				lineHeight: "14px",
				whiteSpace: "nowrap"
			},
			"& .MuiGrid-root > *": {
				width: "100%",

				"& span": {
					display: "block",
					flex: 1,
					borderBottom: "1px dotted #647083",
					margin: "0 4px"
				}
			}
		}
	},

	orderCard: {
		display: "flex",
		flexDirection: "column",
		height: "100%",

		padding: 20,
		boxSizing: "border-box",
		boxShadow: "0px 2px 8px rgba(142, 150, 168, 0.24)",
		backgroundColor: "white",
		borderRadius: 4,

		"@media(max-width: 1199px)": {
			padding: 12,
		}
	},
	orderCardHead: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		marginBottom: 12
	},
	orderCardBody: {
		marginBottom: 16
	},
	orderCardFooter: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-end",
		justifyContent: "flex-end",
		marginTop: "auto",

		"& > *": {
			marginTop: "4px"
		}
	},
	orderCardName: {
		fontSize: 20,
		lineHeight: "24px",
		color: "black",
		fontWeight: "500",

		"@media(max-width: 1199px)": {
			fontSize: 18,
			lineHeight: "20px",
		}
	},

	orderCardEstimateTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	orderCardEstimate: {
		fontSize: 14,
		lineHeight: "18px",

		marginBottom: 20
	},

	filesTitle: {
		fontSize: 16,
		lineHeight: "18px",
		marginBottom: 6
	},
	files: {
		"& > * > *": {
			width: 60,
			height: 60,
			borderRadius: 4,
			overflow: "hidden",
			border: "1px solid red",
			objectFit: "contain"
		}
	},

	orderPriceBig: {
		fontSize: 20,
		lineHeight: "20px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},
	orderPriceSmall: {
		fontSize: 16,
		lineHeight: "18px",
		color: "rgba(0,0,0,0.7)",

		"& span": {
			color: "black"
		}
	},
}));

export default React.memo(WorkList)