import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,

	Box,
	Grid,
	Button,
	TextField,
	Typography
} from "@mui/material";
import {
	Notification,
	notificationTypes
} from "../../../../../common/Notification";
import agent from "../../../../../agent/agent";

const initForm = {
	firstName: "",
	lastName: "",
}

class DialogRegistrationUser extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {...initForm},
			phone: "",

			onSubmit: null,

			isOpen: false,
			isCreated: false
		}
	}

	open = ({ phone, onSubmit }) => {
		this.setState({
			phone,
			onSubmit,

			isOpen: true
		})
	}
	close = () => {
		this.setState({
			form: {...initForm},
			phone: "",
			isOpen: false,
		})
	}

	changeForm = ({ target }) => {
		const { name, value } = target;

		let form = { ...this.state.form }
		form[name] = value;
		this.setState({ form })
	}

	submit = async () => {
		const error = this.validation();
		if (error) {
			Notification({
				message: error,
				type: notificationTypes.error,
			})

			return
		}

		this.setState({ isCreated: true })

		const response = await agent.post(`/user/mechanic/client-by-phone`, {
			...this.state.form,
			phone: this.state.phone
		}).then((res) => {
			return res.data
		}).catch((err) => {
			return { error: err.response }
		})
		if (response.error) {
			this.setState({ isCreated: false })

			Notification({
				message: response.error?.data?.details || "Ошибка сервера",
				type: notificationTypes.error
			})

			return
		}

		this.state.onSubmit();
		this.close();

		this.setState({ isCreated: false })
	}

	validation = () => {
		const { form } = this.state;

		let errors = [];
		if (!form.firstName) {
			errors.push("Введите Имя клиента");
		}
		if (!form.lastName) {
			errors.push("Введите Фамилию клиента");
		}

		return errors.join(";<br/>")
	}

	render () {
		const {
			form,
			isOpen,
			isCreated
		} = this.state;

		return (
			<Dialog
				open={isOpen}
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle>
					<Typography variant="h3">Регистрация клиента</Typography>
				</DialogTitle>

				<DialogContent>
					<Grid container spacing={2} sx={{paddingTop: 1}}>
						<Grid item xs={12}>
							<TextField
								value={form.firstName}
								disabled={isCreated}
								name="firstName"
								variant="outlined"
								size="small"
								label="Имя клиента"
								placeholder="Введите Имя клиента"
								fullWidth
								onChange={this.changeForm}
							/>
						</Grid>
						<Grid item xs={12}>
							<TextField
								value={form.lastName}
								disabled={isCreated}
								name="lastName"
								variant="outlined"
								size="small"
								label="Фамилия клиента"
								placeholder="Введите Фамилию клиента"
								fullWidth
								onChange={this.changeForm}
							/>
						</Grid>
						<Grid item xs={12} container spacing={2}>
							<Grid item xs={6}>
								<Button fullWidth variant="outlined" color="primary" disabled={isCreated} onClick={this.close}>Закрыть</Button>
							</Grid>
							<Grid item xs={6}>
								<Button fullWidth variant="contained" color="primary" disabled={isCreated} onClick={this.submit}>
									Зарегистрировать
								</Button>
							</Grid>
						</Grid>
					</Grid>
				</DialogContent>
			</Dialog>
		)
	}
}

export default DialogRegistrationUser