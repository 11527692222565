import React from "react";
import {isIos} from "../../common/Platform";

const VideoPreview = ({ onClick, file }) => {
	if (isIos()) {
		let type = file?.file?.type;
		if (type === "video/quicktime") {
			type = "video/mp4";
		}

		return (
			<video
				autoPlay={true}
				onClick={onClick}

				style={{
					maxWidth: "100%",
					maxHeight: "100%"
				}}
			>
				<source
					src={URL.createObjectURL(file.file)}
					type={type}
				/>
			</video>
		)
	}

	return (
		<video
			src={URL.createObjectURL(file.file)}
			onClick={onClick}

			style={{
				maxWidth: "100%",
				maxHeight: "100%"
			}}
		/>
	)
}

export default VideoPreview