import React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,

	Box,
	Grid,
	Button,
	Divider,
	TextField,
	IconButton,
	Typography,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from "@mui/material";
import {
	makeStyles,
	withStyles
} from "@mui/styles";
import {
	Send as SendIcon,
	Delete as DeleteIcon
} from "@mui/icons-material";
import {
	IMaskInput
} from "react-imask";
import {
	FileUploader
} from "../../../../../components";
import {
	Notification,
	notificationTypes
} from "../../../../../common/Notification";
import {convertorNumber} from "../../../../../helper/convertor";
import optimizationImageVideo from "../../../../../common/OptimizationImageVideo";

const initForm = {
	applicationDescription: "",
	applicationPrice: "",
	partsList: [],
	files: [],
};

class DialogCreateWork extends React.PureComponent {
	constructor(props) {
		super(props);

		this.state = {
			form: {...initForm},

			isOpen: false,
			isEdit: false,
		}
	}

	open = ({ onSubmit, initForm: editInitForm }) => {
		this.setState({
			form: editInitForm || initForm,
			onSubmit,

			isOpen: true,
			isEdit: Boolean(Object.keys(editInitForm || {}).length > 0)
		})
	}
	close = () => {
		this.setState({
			form: {...initForm},
			isOpen: false,
			isEdit: false,
		})
	}

	changeForm = ({ target }) => {
		const { name, value } = target;

		let form = { ...this.state.form }
		form[name] = value;

		this.setState({ form })
	}

	create = async () => {
		const error = this.checkValidation()
		if (error) {
			Notification({
				message: error,
				type: notificationTypes.error
			})

			return
		}

		this.state.onSubmit(this.state.form, true);
	}
	checkValidation = () => {
		const { form } = this.state;

		let errors = [];

		if (!form.applicationDescription) {
			errors.push(`Введите описание работы`)
		}
		if (!form.applicationPrice) {
			errors.push(`Введите стоимость работы`)
		}

		return errors.join(";<br/>")
	}

	render () {
		const {
			form,

			isOpen,
			isEdit
		} = this.state;
		const {
			classes
		} = this.props;

		if (!isOpen) {
			return null
		}

		return (
			<Dialog
				fullWidth
				maxWidth="md"
				scroll="body"
				open={isOpen}
				onClose={this.close}
			>
				<DialogTitle>
					<Typography variant="h3">
						{Boolean(isEdit) ? "Редактирование работы" : "Добавление работы"}
					</Typography>
				</DialogTitle>

				<DialogContent>

					<Divider className={classes.divider} sx={{ marginTop: "0!important" }}/>

					{/* Описание работы */}
					<Box mb={2}>
						<Typography variant="h4">Основная информация</Typography>
					</Box>
					<Box mb={1}>
						<TextField
							value={form.applicationDescription}
							name="applicationDescription"
							label="Описание работы"
							placeholder="Введите описание работы"
							variant="outlined"
							size="small"
							fullWidth
							multiline
							rows={4}
							onChange={this.changeForm}
						/>
					</Box>
					<Box>
						<TextField
							value={form.applicationPrice}
							label="Стоимость работ"
							variant="outlined"
							size="small"
							fullWidth

							inputProps={{
								scale: 2,
								mask: Number,
								thousandsSeparator: "",
								radix: ".",
								mapToRadix: [','],
								onComplete: (value) => this.changeForm({target: { value, name: "applicationPrice" }})
							}}
							InputProps={{
								inputComponent: IMaskInput
							}}
							InputLabelProps={{
								shrink: Boolean(form.applicationPrice !== ""),
							}}
						/>
					</Box>

					<Divider className={classes.divider}/>

					<Box mb={2}>
						<Typography variant="h4">Перечень запчастей</Typography>
					</Box>
					<PartsListContent
						data={form.partsList}
						onChange={this.changeForm}
					/>

					<Divider className={classes.divider}/>

					<Box mb={2}>
						<Typography variant="h4">Фото / видео</Typography>
					</Box>
					<FileUploader
						value={form.files || []}
						multiple={true}
						name="files"
						accept={{
							"image/gif": [".gif"],
							"image/jpeg": [".jpeg", ".jpg"],
							"image/png": [".png"],
							"image/webp": [".webp"],

							"video/mpeg": [".mpeg"],
							"video/mp4": [".mp4"],
							"video/ogg": [".ogv"],
							"video/webm": [".webm"],
							"video/x-msvideo": [".avi"],
							"video/3gpp": [".3gp"],
							"video/3gpp2": [".3g2"],
						}}
						onChange={this.changeForm}
					/>

					<Divider className={classes.divider}/>

					<Grid container justifyContent="flex-end" spacing={1}>
						<Grid item>
							<Button variant="outlined" size="small" onClick={this.close}>Закрыть</Button>
						</Grid>
						<Grid item>
							<Button variant="contained" size="small" onClick={this.create}>
								{Boolean(isEdit) ? "Изменить" : "Добавить"}
							</Button>
						</Grid>
					</Grid>

				</DialogContent>
			</Dialog>
		)
	}
}
const PartsListContent = React.memo((props) => {
	const {
		data,
		onChange
	} = props;
	const classes = useStyles();
	const [part, setPart] = React.useState({ name: "", price: "" });

	const handleChangePart = ({ target }) => {
		const { name, value } = target;
		let newPart = { ...part };
		newPart[name] = value;
		setPart(newPart);
	}
	const handleSubmitNewPart = () => {
		if (!part.name || !part.price) {
			Notification({
				message: `Заполните "Название запчасти" и "Стоимость запчасти"`,
				type: notificationTypes.error
			})

			return
		}

		let newData = [...data];
		newData.push({
			...part,
			index: (Math.random() + 1).toString(36).substring(7)
		})
		onChange({
			target: {
				name: "partsList",
				value: newData
			}
		})

		setPart({ name: "", price: "" })
	}
	const handleRemovePart = (index) => {
		let newData = [...data];
		newData.splice(index, 1);
		onChange({
			target: {
				name: "partsList",
				value: newData
			}
		})
	}

	return (
		<>

			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>Название запчасти</TableCell>
						<TableCell align="right">Стоимость</TableCell>
						<TableCell align="right" width={60}/>
					</TableRow>
				</TableHead>
				<TableBody>
					{(data || []).map((item, index) => (
						<TableRow key={item.index} className={classes.tableRowMaterial}>
							<TableCell>
								<span className="visible-mobile">Наименование запчасти -&nbsp;</span>
								{ item.name }
							</TableCell>
							<TableCell>
								<span className="visible-mobile">Стоимость запчасти -&nbsp;</span>
								{ convertorNumber(item.price, 2, '.') } ₽
							</TableCell>
							<TableCell align="center">
								<IconButton onClick={handleRemovePart.bind(this, index)}>
									<DeleteIcon color="primary" sx={{ fontSize: 16 }}/>
								</IconButton>
							</TableCell>
						</TableRow>
					))}


					<TableRow className={classes.tableForm}>
						<TableCell>
							<TextField
								value={part.name}
								name="name"
								fullWidth
								variant="outlined"
								placeholder="Введите название запчасти"
								onChange={handleChangePart}
							/>
						</TableCell>
						<TableCell>
							<TextField
								value={part.price}
								name="price"
								fullWidth
								variant="outlined"
								placeholder="Введите стоимость запчасти"
								inputProps={{
									scale: 2,
									mask: Number,
									thousandsSeparator: "",
									radix: ".",
									mapToRadix: [','],
									onComplete: (event, { _unmaskedValue: value }) => handleChangePart({target: { value, name: "price" }})
								}}
								InputProps={{
									inputComponent: IMaskInput
								}}
							/>
						</TableCell>
						<TableCell align="center">
							<IconButton onClick={handleSubmitNewPart}>
								<SendIcon color="primary" sx={{ fontSize: 16 }}/>
								<span className="visible-mobile">Добавить</span>
							</IconButton>
						</TableCell>
					</TableRow>
				</TableBody>
			</Table>

		</>
	)
})


const useStyles = makeStyles(() => ({
	table: {
		"& .MuiTableHead-root": {
			background: "#FF3F3F"
		},
		"& .MuiTableHead-root .MuiTableCell-root": {
			fontSize: 14,
			lineHeight: "14px",
			fontWeight: "500",
			color: "white",
		},

		"& .MuiOutlinedInput-root": {
			fontSize: 14,
			lineHeight: "16px",
		},
		"& .MuiOutlinedInput-input": {
			height: 20,
			padding: "4px 8px"
		},

		"@media(max-width: 1199px)": {
			"& .MuiTableHead-root": {
				display: "none"
			},
			"& .MuiTableBody-root .MuiTableRow-root": {
				display: "flex",
				flexDirection: "column"
			},
			"& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root": {
				border: "none",
				padding: 0
			},
		}
	},

	tableForm: {
		"@media(max-width: 1199px)": {
			marginTop: 16,

			"& > *": {
				marginTop: 8,
				"&:first-child": {
					marginTop: 0
				}
			},

			"& .MuiButtonBase-root": {
				width: "100%",
				height: 32,
				background: "white",
				borderRadius: 4,
				border: "1px solid #ff3f3f",
				color: "#ff3f3f",
				fontSize: 16,
				lineHeight: "16px",
			},
			"& .MuiOutlinedInput-input": {
				height: 32
			},
		}
	},
	tableRowMaterial: {
		"@media(max-width: 1199px)": {
			padding: 8,
			borderRadius: 4,
			backgroundColor: "white",
			boxShadow: "0 0 3px 0px rgb(0 0 0 / 30%)",

			"& td": {
				"&:nth-child(1)": {
					fontSize: "18px!important",
					lineHeight: "22px!important",
					color: "black!important",
					marginBottom: 6
				},
				"&:nth-child(2)": {
					fontSize: "14px!important",
					lineHeight: "18px!important",
					color: "black!important",
					marginBottom: 6
				},

				"& .MuiButtonBase-root": {
					width: "100%",
					height: 24,
					background: "white",
					borderRadius: 4,
					border: "1px solid #ff3f3f",
					color: "#ff3f3f",
					fontSize: 16,
					lineHeight: "16px",
				}
			},

			"& .visible-mobile": {
				display: "contents"
			}
		}
	},
}));
const styles = {
	divider: {
		margin: "24px -24px",
		"@media(max-width: 1199px)": {
			margin: "12px -8px",
		}
	}
}
DialogCreateWork = withStyles(styles)(DialogCreateWork)

export default DialogCreateWork