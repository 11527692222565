const stringTruncation = (string = "", {
	startCount,
	endCount,
	delimiter
} = {}) => {
	const leftPart = string.substring(0, (startCount || 4));
	const rightPart = string.substring(string.length - (endCount || 4), string.length);

	return [
		leftPart,
		(delimiter || "..."),
		rightPart
	].join("")
}
const addZero = (number, length) => {

	let my_string = '' + number;
	while (my_string.length < length) {
		my_string = '0' + my_string;
	}

	return my_string;
}
const phoneFormat = (str) => {
	let cleaned = ('' + str).replace(/\D/g, '');
	let match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);

	if (match) {
		return ['+', match[1], ' (', match[2], ') ', match[3], '-', match[4], '-', match[5]].join('')
	}

	return str;
}

export {
	stringTruncation,
	addZero,
	phoneFormat,
}
