import React from 'react';
import {compose} from 'recompose';
import {connect} from 'react-redux';

// Routes
import ClientRoutes from "./Client";
import {setUser} from "../states/global";

class Router extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true
		};
	}

	componentDidMount = async () => {
		await this.props.setUser();
		this.setState({
			isLoading: false
		})
	}

	render() {
		if (this.state.isLoading) {
			return null
		}

		return (
			<ClientRoutes/>
		)
	}
}

export default compose(
	connect(
		state => ({  }),
		dispatch => ({
			setUser: () => dispatch(setUser())
		}),
	),
)(Router);
