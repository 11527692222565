import React from "react";
import {
	Box,
	Grid,
	Button,
	Skeleton,
	Pagination,

	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from "@mui/material";
import {
	makeStyles
} from "@mui/styles";
import {convertorNumber} from "../../../../../helper/convertor";
import moment from "moment";
import orderStatus from "../../../../../constants/orderStatus";
import {useHistory} from "react-router-dom";
import { useDispatch } from "react-redux";
import {setChosenApplicationId} from "../../../../../states/global";

const TableApplications = (props) => {
	const {
		data,
		isLoad,
		pagination,
		onChangePagination
	} = props;
	const classes = useStyles();

	const handleChangePage = (event, page) => {
		let newPagination = { ...pagination };
		newPagination.page = page;

		onChangePagination( newPagination )
	}

	return (
		<>

			<Table className={classes.table}>
				<TableHead>
					<TableRow>
						<TableCell>№ заявки</TableCell>
						<TableCell>Статус заявки</TableCell>
						<TableCell>Наименование заявки</TableCell>
						<TableCell>Перечень работ</TableCell>
						<TableCell>Клиент</TableCell>
						<TableCell align="right">Стоимость</TableCell>
						<TableCell align="right">Дата / время создания</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{Boolean(!isLoad) && (
						<>
							{data.map((item, index) => (
								<RowCustom
									key={item.id}
									item={item}
								/>
							))}
						</>
					)}

					{Boolean(isLoad) && (
						<TableRow>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
							<TableCell><Skeleton/></TableCell>
						</TableRow>
					)}
				</TableBody>
			</Table>

			<Box mt={ { xs: "8px", lg: "16px" } }/>

			{ data.length > 0 && (
				<Pagination
					page={ Number( pagination.page ) }
					count={ Number( pagination.totalPage ) }

					onChange={ handleChangePage }
				/>
			) }

		</>
	)
}
const RowCustom = React.memo((props) => {
	const {
		item
	} = props;
	const history = useHistory();
	const classes = useStyles();
	const service = item?.orderServices?.[0] || {};
	const fullNameClient = [item.client?.firstName, item.client?.lastName].join(" ");
	const dispatch = useDispatch();

	const handleOpenService = () => {
		dispatch(setChosenApplicationId(`el-${item.id}`));
		history.push(`/applications/${ item.id }`);
	}

	return (
		<TableRow
			className={classes.tableRow}
			hover
			onClick={handleOpenService}
			id={`el-${item.id}`}
		>
			<TableCell className={classes.tableRowNumber}>
				<span className="visible-mobile">№ заявки -&nbsp;</span> { item.id }
			</TableCell>
			<TableCell className={classes.tableRowStatus}>
				<Grid container>
					<Grid item>
						<Box
							className={classes.status}
							style={{
								backgroundColor: orderStatus[item.status]?.color
							}}
						>
							{orderStatus[item.status]?.label}
						</Box>
					</Grid>
				</Grid>
			</TableCell>
			<TableCell className={classes.tableRowName}>
				{ item.info }
			</TableCell>
			<TableCell className={classes.tableRowParts}>
				<ol>
					{(item?.orderServices || []).map((t) => (
						<li>{t.description} ({convertorNumber(t.cost, 2, '.')} ₽)</li>
					))}
				</ol>
			</TableCell>
			<TableCell className={classes.tableRowUserName}>
				<span className="visible-mobile">Клиент -&nbsp;</span>
				{ fullNameClient }
			</TableCell>
			<TableCell className={classes.tableRowPrice} align="right">
				{convertorNumber(service.cost, 2, '.')} ₽
			</TableCell>
			<TableCell className={classes.tableRowDate} align="right">
				{moment(item.createdAt).format("DD.MM.YYYY / HH:mm:ss")}
			</TableCell>
		</TableRow>
	)
})

const useStyles = makeStyles(() => ({
	status: {
		padding: "4px 8px",
		borderRadius: 999,
		backgroundColor: "#259F06",

		fontSize: 12,
		lineHeight: "14px",
		color: "white"
	},

	table: {
		"@media(max-width: 1199px)": {
			display: "flex",
			flexDirection: "column",

			"& .MuiTableHead-root": {
				display: "none"
			}
		}
	},

	tableRow: {
		"@media(max-width: 1199px)": {
			display: "flex",
			flexWrap: "wrap",
			padding: 12,
			background: "white",
			borderRadius: 12,
			marginTop: 12,

			"& td": {
				border: "none!important",
				padding: "0!important"
			}
		}
	},
	tableRowNumber: {
		"@media(max-width: 1199px)": {
			marginTop: 2,
			marginBottom: 16,
			display: "flex",
			width: "50%",
		}
	},
	tableRowStatus: {
		"@media(max-width: 1199px)": {
			width: "50%",
			display: "flex",

			"& > *": {
				marginLeft: "auto",
				width: "auto"
			}
		}
	},
	tableRowName: {
		"@media(max-width: 1199px)": {
			width: "100%",
			textAlign: "center",
			fontSize: "18px!important",
			lineHeight: "20px!important",
			fontWeight: "500!important",
			color: "black!important",
			marginBottom: 12
		}
	},
	tableRowParts: {
		maxWidth: 220,

		"@media(max-width: 1199px)": {
			width: "100%",
			maxWidth: "100%",
			marginBottom: 16,

			"& ol > li": {
				marginTop: 4,
				"&:first-child": {
					marginTop: 0
				}
			}
		}
	},
	tableRowUserName: {
		"@media(max-width: 1199px)": {
			display: "flex",
			width: "100%",
			textAlign: "center",
			fontSize: "16px!important",
			lineHeight: "18px!important",
			fontWeight: "500!important",
			color: "black!important",
			marginBottom: 16
		}
	},
	tableRowPrice: {
		"@media(max-width: 1199px)": {
			width: "50%",
			textAlign: "left",
			fontSize: "16px!important",
			lineHeight: "18px!important"
		}
	},
	tableRowDate: {
		"@media(max-width: 1199px)": {
			width: "50%",
			fontSize: "16px!important",
			lineHeight: "18px!important"
		}
	},
}));

export default React.memo(TableApplications)
